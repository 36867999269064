// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog/post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-c-impresa-pulizie-cesano-boscone-js": () => import("./../../../src/pages/c/impresa-pulizie-Cesano-Boscone.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-cesano-boscone-js" */),
  "component---src-pages-c-impresa-pulizie-firenze-js": () => import("./../../../src/pages/c/impresa-pulizie-Firenze.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-firenze-js" */),
  "component---src-pages-c-impresa-pulizie-legnano-js": () => import("./../../../src/pages/c/impresa-pulizie-Legnano.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-legnano-js" */),
  "component---src-pages-c-impresa-pulizie-lodi-js": () => import("./../../../src/pages/c/impresa-pulizie-Lodi.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-lodi-js" */),
  "component---src-pages-c-impresa-pulizie-ravenna-js": () => import("./../../../src/pages/c/impresa-pulizie-Ravenna.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-ravenna-js" */),
  "component---src-pages-c-impresa-pulizie-roma-js": () => import("./../../../src/pages/c/impresa-pulizie-Roma.js" /* webpackChunkName: "component---src-pages-c-impresa-pulizie-roma-js" */),
  "component---src-pages-c-milano-impresa-pulizie-milano-js": () => import("./../../../src/pages/c/milano/impresa-pulizie-Milano.js" /* webpackChunkName: "component---src-pages-c-milano-impresa-pulizie-milano-js" */),
  "component---src-pages-certificazioni-iso-14001-js": () => import("./../../../src/pages/certificazioni/iso-14001.js" /* webpackChunkName: "component---src-pages-certificazioni-iso-14001-js" */),
  "component---src-pages-certificazioni-iso-9001-js": () => import("./../../../src/pages/certificazioni/iso-9001.js" /* webpackChunkName: "component---src-pages-certificazioni-iso-9001-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-servizio-disinfestazioni-js": () => import("./../../../src/pages/servizio/disinfestazioni.js" /* webpackChunkName: "component---src-pages-servizio-disinfestazioni-js" */),
  "component---src-pages-servizio-giardinaggio-trattamento-verde-js": () => import("./../../../src/pages/servizio/giardinaggio-trattamento-verde.js" /* webpackChunkName: "component---src-pages-servizio-giardinaggio-trattamento-verde-js" */),
  "component---src-pages-servizio-imbiancatura-js": () => import("./../../../src/pages/servizio/imbiancatura.js" /* webpackChunkName: "component---src-pages-servizio-imbiancatura-js" */),
  "component---src-pages-servizio-lavaggio-vetrate-tapparelle-insegne-js": () => import("./../../../src/pages/servizio/lavaggio-vetrate-tapparelle-insegne.js" /* webpackChunkName: "component---src-pages-servizio-lavaggio-vetrate-tapparelle-insegne-js" */),
  "component---src-pages-servizio-lucidatura-levigatura-mamo-e-parquet-js": () => import("./../../../src/pages/servizio/lucidatura-levigatura-mamo-e-parquet.js" /* webpackChunkName: "component---src-pages-servizio-lucidatura-levigatura-mamo-e-parquet-js" */),
  "component---src-pages-servizio-portierato-js": () => import("./../../../src/pages/servizio/portierato.js" /* webpackChunkName: "component---src-pages-servizio-portierato-js" */),
  "component---src-pages-servizio-pulizia-appartamenti-js": () => import("./../../../src/pages/servizio/pulizia-appartamenti.js" /* webpackChunkName: "component---src-pages-servizio-pulizia-appartamenti-js" */),
  "component---src-pages-servizio-pulizia-condomini-js": () => import("./../../../src/pages/servizio/pulizia-condomini.js" /* webpackChunkName: "component---src-pages-servizio-pulizia-condomini-js" */),
  "component---src-pages-servizio-pulizia-uffici-js": () => import("./../../../src/pages/servizio/pulizia-uffici.js" /* webpackChunkName: "component---src-pages-servizio-pulizia-uffici-js" */),
  "component---src-pages-servizio-pulizie-industriali-js": () => import("./../../../src/pages/servizio/pulizie-industriali.js" /* webpackChunkName: "component---src-pages-servizio-pulizie-industriali-js" */),
  "component---src-pages-servizio-ristrutturazione-js": () => import("./../../../src/pages/servizio/ristrutturazione.js" /* webpackChunkName: "component---src-pages-servizio-ristrutturazione-js" */),
  "component---src-pages-servizio-rotazioni-sacchi-e-raccolta-differenziata-js": () => import("./../../../src/pages/servizio/rotazioni-sacchi-e-raccolta-differenziata.js" /* webpackChunkName: "component---src-pages-servizio-rotazioni-sacchi-e-raccolta-differenziata-js" */),
  "component---src-pages-servizio-sanificazione-js": () => import("./../../../src/pages/servizio/sanificazione.js" /* webpackChunkName: "component---src-pages-servizio-sanificazione-js" */),
  "component---src-pages-servizio-spazza-camino-e-pulizia-canna-fumaria-js": () => import("./../../../src/pages/servizio/spazza-camino-e-pulizia-canna-fumaria.js" /* webpackChunkName: "component---src-pages-servizio-spazza-camino-e-pulizia-canna-fumaria-js" */),
  "component---src-pages-servizio-trattamento-pavimentazione-e-lavaggio-moquette-js": () => import("./../../../src/pages/servizio/trattamento-pavimentazione-e-lavaggio-moquette.js" /* webpackChunkName: "component---src-pages-servizio-trattamento-pavimentazione-e-lavaggio-moquette-js" */)
}

